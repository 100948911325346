/* Global Styles */
* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}
